import axios from 'axios'

const BASE_URL = process.env.VUE_APP_BASE_URL

const token = sessionStorage.getItem('token')
axios.defaults.headers.common.Authorization = `Bearer ${token}`

async function getData(ec, startDate, endDate) {
  try {
    return await axios.get(`${BASE_URL}/veripag/ecs/balance?id=${ec}&startDate=${startDate}&endDate=${endDate}`)
  } catch ({ response }) {
    return response
  }
}

async function postData(amount, description, ecs) {
  try {
    return await axios.post(`${BASE_URL}/veripag/ecs/balance`, {
      amount,
      type: 3,
      description,
      ecs,
    })
  } catch ({ response }) {
    return response
  }
}

export { getData, postData }
