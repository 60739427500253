<template>
  <b-container>
    <b-overlay
      :show="showLoading"
      :opacity="1"
      variant="light"
      rounded="sm"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong class="mr-1">Carregando Dados, Aguarde...</strong>
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <b-card
        class="card-statistics"
        title="Extrato do Cliente"
      >

        <validation-observer
          ref="simpleRules"
          #default="{invalid}"
        >
          <b-form
            class="needs-validation"
            @submit.prevent
          >
            <b-row>
              <b-col
                xl="4"
                md="6"
                sm="12"
              >
                <b-form-group
                  label="EC"
                  label-for="ec"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="EC"
                    rules="required"
                  >
                    <b-form-input
                      id="ec"
                      v-model="ec"
                      v-mask="'######'"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Informe o Número do EC"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                xl="4"
                md="6"
                sm="12"
              >
                <b-form-group
                  label="Data Inicial"
                  label-for="inicial-date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Data Inicial"
                    rules="required"
                  >
                    <b-form-input
                      id="inicial-date"
                      v-model="startDate"
                      :state="errors.length > 0 ? false : null"
                      type="date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                xl="4"
                md="6"
                sm="12"
              >
                <b-form-group
                  label="Data Final"
                  label-for="final-date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Data Final"
                    rules="required"
                  >
                    <b-form-input
                      id="final-date"
                      v-model="finalDate"
                      :state="errors.length > 0 ? false : null"
                      type="date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <div>
              <b-button
                variant="primary"
                :disabled="invalid"
                @click="validationForm"
              >
                Consultar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <b-card
        v-if="requestHasDone"
        class="card-statistics"
      >
        <b-col md="12">
          <h4 class="text-center">
            {{ ecName }}
          </h4>
        </b-col>

        <b-card-body>
          <b-row class="d-flex justify-content-around">
            <b-col
              v-for="item in statisticsItems"
              :key="item.subtitle"
              md="auto"
              sm="6"
              class="mb-2 mb-md-0"
              :class="item.customClass"
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    :variant="item.color"
                  >
                    <feather-icon
                      size="24"
                      :icon="item.icon"
                      style="color: green;"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.title }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ item.subtitle }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card v-if="requestHasDone">
        <b-col md="12">
          <b-row>
            <b-col
              sm="12"
              md="6"
              xl="8"
            >

              <b-form-input
                v-model="filterTable"
                type="text"
                class="d-inline-block"
              />
            </b-col>

            <b-col
              sm="12"
              md="3"
              xl="2"
            >
              <b-form-select
                v-model="sortBy"
                :options="fieldsToSort"
              />

            </b-col>
            <b-col
              sm="12"
              md="3"
              xl="2"
            >
              <b-form-select
                v-model="sortDesc"
              >
                <option :value="false">
                  Crescente
                </option>
                <option :value="true">
                  Decrescente
                </option>
              </b-form-select>
            </b-col>
          </b-row>

          <b-table
            responsive="xl"
            class="text-center mt-1"
            :fields="tableStatementField"
            :items="tableStatementItems"
            :current-page="currentPage"
            :per-page="12"
            striped
            hover
            :filter="filterTable"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :sort-compare="mySortCompare"
            @filtered="onFiltered"
          >
            <template #cell(amount)="data">
              <span :class="amountFeedback(data)"> {{ data.item.amount }} </span>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="12"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-card>
    </b-overlay>
  </b-container>
</template>

<script>
import {
    BContainer,
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormInput,
    BTable,
    BPagination,
    BForm,
    BFormGroup,
    BButton,
    BSpinner,
    BOverlay,
    BFormSelect,
    BIcon,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { DateTime } from 'luxon';
import { required } from '@validations';

import { getData } from '@/service/statement/statement';
import MerchantService from '@/service/merchant';

const formatter = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
});

const convertStringToNumber = number => +number.replace(/[R$.\s]/g, '').replace(',', '.');

export default {
    components: {
        BContainer,
        BCard,
        BRow,
        BCol,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BFormInput,
        BTable,
        BPagination,
        BForm,
        BFormGroup,
        ValidationProvider,
        ValidationObserver,
        BButton,
        BSpinner,
        BOverlay,
        BFormSelect,
        BIcon,
    },
    data() {
        return {
            required,
            statisticsItems: [
                {
                    icon: 'DollarSignIcon',
                    color: 'light-success',
                    title: 'R$ 10.000,00',
                    subtitle: 'Disponivel',
                    customClass: '',
                },

                {
                    icon: 'DollarSignIcon',
                    color: 'light-success',
                    title: 'R$ 9.745,00',
                    subtitle: 'Lançamentos Futuros',
                    customClass: '',
                },
            ],
            tableStatementItems: null,
            tableStatementField: [
                { key: 'id', label: 'ID' },
                { key: 'created_date', label: 'Data da Criação' },
                { key: 'movement_type_name', label: 'Tipo' },
                { key: 'description', label: 'Descrição' },
                { key: 'amount', label: 'Valor' },
                { key: 'fee', label: 'Taxas' },
                { key: 'balance_amount', label: 'Valor Final' },
            ],
            totalRows: 0,
            currentPage: 1,
            filterTable: '',
            requestHasDone: false,
            ec: null,
            startDate: null,
            finalDate: null,
            sortTable: true,
            showLoading: false,
            sortBy: 'created_date',
            sortDesc: false,
            sortDirection: 'asc',
            fieldsToSort: [
                { value: 'amount', text: 'Valor' },
                { value: 'fee', text: 'Taxas' },
                { value: 'balance_amount', text: 'Valor Final' },
                { value: 'movement_type_name', text: 'Tipo' },
                { value: 'created_date', text: 'Data da Criação' },
            ].sort((a, b) => a.text.localeCompare(b.text)),
        };
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.getData();
                }
            });
        },

        amountFeedback(key) {
            const value = convertStringToNumber(key.value);
            return value < 0 ? 'text-danger' : value > 0 ? 'text-success' : '';
        },

        mySortCompare(a, b, key) {
            if (key === 'created_date') {
                const [dayA, monthA, yearA] = a[key].split('/');

                const [dayB, monthB, yearB] = b[key].split('/');

                const aDate = Date.parse(`${yearA}/${monthA}/${dayA}`);
                const bDate = Date.parse(`${yearB}/${monthB}/${dayB}`);

                return aDate - bDate;
            }

            if (key === 'amount') {
                const aValue = convertStringToNumber(a[key]);
                const bValue = convertStringToNumber(b[key]);

                return aValue - bValue;
            }

            if (key === 'balance_amount') {
                const aValue = convertStringToNumber(a[key]);
                const bValue = convertStringToNumber(b[key]);

                return aValue - bValue;
            }

            if (key === 'fee') {
                const aValue = convertStringToNumber(a[key]);
                const bValue = convertStringToNumber(b[key]);

                return aValue - bValue;
            }
            return false;
        },

        async getData() {
            this.showLoading = true;

            const { ec, startDate, finalDate } = this;

            const responseMerchantInfo = await MerchantService.getMerchant(ec);
            this.ecName = responseMerchantInfo.data.name;

            const { data, status } = await getData(ec, startDate, finalDate);

            if (status !== 200) {
                this.requestHasDone = false;
                return;
            }
            const { available, waiting_funds, releases } = data;

            this.statisticsItems[0].title = formatter.format(available);
            this.statisticsItems[1].title = formatter.format(waiting_funds);

            this.totalRows = releases.length;
            this.tableStatementItems = releases.map(item => {
                const {
                    amount, balance_amount, fee, created_date, description,
                } = item;
                return {
                    ...item,
                    amount: formatter.format(amount),
                    balance_amount: formatter.format(balance_amount),
                    fee: formatter.format(fee),
                    created_date: DateTime.fromISO(created_date).toFormat(
                        'dd/MM/yyyy',
                    ),
                    description: description || '---------',
                };
            });
            this.requestHasDone = true;
            this.showLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
